<template>
  <HubVue></HubVue>
</template>

<script>
import HubVue from './components/Hub.vue';

export default {
  name: 'App',
  components: {
    HubVue
  },
}
</script>

<style></style>
