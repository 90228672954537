<template>
    <div>
        <div class="flex flex-wrap gap-1 place-content-center items-center bg-red-100 p-5 mt-0 m-5 rounded-lg">
            <span class="flex flex-wrap gap-1">
                <i class="gg-info text-red-600"></i>
                <span class="text-sm text-red-600">{{ $t("IVY is currently in BETA phase, thus meaning some features may not function as intended.") }}</span>
            </span>
            <span class="text-sm text-red-600">{{ $t("Feel free to contact us by mail for each issue you may encounter")}}
                <a class="text-red-700" href="mailto:support@codalem.com">support@codalem.com</a>
            </span>
        </div>

        <div class="flex flex-wrap gap-8 w-full justify-center items-center">
            <div class="bg-zinc-50 hover:bg-zinc-100 rounded-lg drop-shadow-lg w-[400px] h-[200px] cursor-pointer" @click="redirectTo('CONFIGURATOR_PAGE')">
                <div class="grid grid-cols-2 w-full h-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/robotics.svg" />
                    <h2 class="text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your configurator")}}</h2>
                </div>
            </div>

            <div v-if="isAdmin" class="bg-zinc-50 hover:bg-zinc-100 rounded-lg drop-shadow-lg w-[400px] h-[200px] cursor-pointer" @click="redirectTo('ADMIN_USERS')">
                <div class="grid grid-cols-2 w-full h-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/startup-teamwork.svg" />
                    <h2 class="text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your users")}}</h2>
                </div>
            </div>

            <div v-if="isAdmin" class="bg-zinc-50 hover:bg-zinc-100 rounded-lg drop-shadow-lg w-[400px] h-[200px] cursor-pointer" @click="redirectTo('ITEMS_PAGE')">
                <div class="grid grid-cols-2 w-full h-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/artifact.svg" />
                    <h2 class="text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your items")}}</h2>
                </div>
            </div>

            <!-- Images from https://delesign.com/free-designs/graphics/ -->
            <div class="bg-zinc-300 hover:bg-zinc-400 rounded-lg drop-shadow-lg w-[400px] h-[230px] cursor-auto">
                <p class="m-4 w-full justify-center flex items-center gap-2">
                    <i class="gg-info text-orange-400"></i>
                    <span class="text-orange-400">{{$t("Upcomming")}}</span>
                </p>
                <div class="grid grid-cols-2 w-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/palette.svg" />
                    <h2 class="text-zinc-600 text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your enterprises")}}</h2>
                </div>
            </div>

            <div class="bg-zinc-300 hover:bg-zinc-400 rounded-lg drop-shadow-lg w-[400px] h-[230px] cursor-auto">
                <p class="m-4 w-full justify-center flex items-center gap-2">
                    <i class="gg-info text-orange-400"></i>
                    <span class="text-orange-400">{{$t("Upcomming")}}</span>
                </p>
                <div class="grid grid-cols-2 w-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/bills.svg" />
                    <h2 class="text-zinc-600 text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your quotes")}}</h2>
                </div>
            </div>

            <div class="bg-zinc-300 hover:bg-zinc-400 rounded-lg drop-shadow-lg w-[400px] h-[230px] cursor-auto">
                <p class="m-4 w-full justify-center flex items-center gap-2">
                    <i class="gg-info text-orange-400"></i>
                    <span class="text-orange-400">{{$t("Upcomming")}}</span>
                </p>
                <div class="grid grid-cols-2 w-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/Statistics.svg" />
                    <h2 class="text-zinc-600 text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your statistics")}}</h2>
                </div>
            </div>

            <div class="bg-zinc-300 hover:bg-zinc-400 rounded-lg drop-shadow-lg w-[400px] h-[230px] cursor-auto">
                <p class="m-4 w-full justify-center flex items-center gap-2">
                    <i class="gg-info text-orange-400"></i>
                    <span class="text-orange-400">{{$t("Upcomming")}}</span>
                </p>
                <div class="grid grid-cols-2 w-full items-center place-items-center">
                    <img class="w-40 m-4" src="../assets/Credit-Card.svg" />
                    <h2 class="text-zinc-600 text-2xl leading-none tracking-tight text-custom_organization-900">{{$t("Your payments")}}</h2>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AuthService from '@ivy/auth-service'
import RouterService from '@ivy/router-service'

export default {
    name: 'HubVue',
    data() {
        return {
            organizations: []
            , context: []
        }
    },
    computed: {
        isAdmin() {
            return AuthService.isAdmin()
        },
    },
    async mounted() {
        // var response = await OrganizationsService.list();
        // this.organizations = response.data;

        // response = await OrganizationsService.get();
        // this.organization = response.data?.organization;
        // // this.organization = this.getCookie('organization');

        // if(!this.organization && this.organizations.length > 0) {
        //     await OrganizationsService.set(this.organizations[0]);
        //     RouterService.on('CONTEXT_SET')
        // }
    },
    methods: {
        redirectTo(location) {
            RouterService.goTo(location);
        }
        // getCookie(cname) {
        //     var name = cname + "=";
        //     var decodedCookie = decodeURIComponent(document.cookie);
        //     var ca = decodedCookie.split(';');
        //     for(var i = 0; i <ca.length; i++) {
        //         var c = ca[i];
        //         while (c.charAt(0) == ' ') {
        //             c = c.substring(1);
        //         }
        //         if (c.indexOf(name) == 0) {
        //             return c.substring(name.length, c.length);
        //         }
        //     }
        //     return "";
        // }
    }
}
</script>